export default {
  data() {
    return {
      pinVerified: false,
      paymentModal: false,
    };
  },
  methods: {
    pinVerification(cb, verified) {
      if (verified) {
        this.pinVerified = true;
        cb(verified);
      }
      this.pinVerified = false;
      this.paymentModal = false;
    },
  },
};
