<template lang='pug'>
	div
		transition(name="fade")
			.spinner(v-if="loading")
				.double-bounce1
				.double-bounce2
</template>
<script>
export default {
	props: {
		loading: Boolean
	}
};
</script>
<style lang="scss" scoped>
.spinner {
	width: 40px;
	height: 40px;
	position: fixed;
	left: 50%;
	top: 50%;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce1{

	background-color:#FF900A;
}

.double-bounce2 {
	background-color: #FF900A;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
</style>
